/* eslint-disable no-underscore-dangle */
import React from 'react';
import ReactDOM from 'react-dom';

import ErrorBoundary from 'components/ErrorBoundary';
import UserContext from 'contexts/UserContext';
import MessagesContext from 'contexts/MessagesContext';
import EnvContext from 'contexts/EnvContext';
import MainLayout from 'layouts/MainLayout';

const RenderToRoot = (Element) => {
  const reactProps = typeof window !== 'undefined' ? window.reactProps : {};

  const element = (
    <ErrorBoundary className="mt-3">
      <EnvContext.Provider value={reactProps.env}>
        <UserContext.Provider value={reactProps._user}>
          <MessagesContext.Provider value={reactProps.messages}>
            <MainLayout activeRef={reactProps.loginCallback}>
              <Element {...reactProps} />
            </MainLayout>
          </MessagesContext.Provider>
        </UserContext.Provider>
      </EnvContext.Provider>
    </ErrorBoundary>
  );
  if (typeof document !== 'undefined') {
    const wrapper = document.getElementById('react-root');
    if (wrapper) {
      if (wrapper.children.length === 0) {
        ReactDOM.render(element, wrapper);
      } else {
        ReactDOM.hydrate(element, wrapper);
      }
    }
  }

  return Element;
};

export default RenderToRoot;
