import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import Spinner from 'components/base/Spinner';

const Button = ({ children, onClick, color, noPadding, outline, loading, disabled, className, href }) => {
  const [clicked, setClicked] = React.useState(false);

  const extraClassnames = className || '';

  const primary = color === 'primary';
  const danger = color === 'danger';

  const cs = classNames({
    'font-bold text-sm transition-all border py-1 rounded-sm duration-300 w-full': true,
    'lg:px-8': !noPadding,
    'opacity-50 cursor-not-allowed': (loading && clicked) || disabled,
    'border-blue-500 bg-blue-500 text-neutral-100': primary && !outline,
    'border-blue-500 inherit-bg text-blue-500': primary && outline,
    'hover:bg-blue-600 hover:text-neutral-100': primary && !disabled,
    'border-red-500 bg-red-500 text-neutral-100': danger && !outline,
    'border-red-500 inherit-bg text-red-500': danger && outline,
    'hover:bg-red-600 hover:text-neutral-100': danger && !disabled,
    [extraClassnames]: extraClassnames,
  });

  if (href) {
    return (
      <button
        className={cs}
        onClick={() => {
          setClicked(true);
          // go to href
          window.location.href = href;
        }}
        type="button"
      >
        {loading && clicked ? (
          <Spinner />
        ) : (
          <div className="flex flex-row items-center gap-1 justify-center">{children}</div>
        )}
      </button>
    );
  }

  return (
    <button
      className={cs}
      onClick={(e) => {
        if ((loading && clicked) || disabled) {
          e.preventDefault();
        } else {
          setClicked(true);
          if (onClick) {
            onClick(e);
          }
        }
      }}
      type="button"
    >
      {loading && clicked ? (
        <Spinner />
      ) : (
        <div className="flex flex-row items-center gap-1 justify-center">{children}</div>
      )}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  noPadding: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  outline: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'danger']),
};

Button.defaultProps = {
  className: '',
  onClick: null,
  noPadding: false,
  loading: false,
  disabled: false,
  href: null,
  color: 'primary',
  outline: false,
};

export default Button;
