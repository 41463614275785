import React from 'react';
import PropTypes from 'prop-types';

const NavItem = ({ item, activeRef }) => {
  if (activeRef && item.href === activeRef) {
    return (
      <a href={item.href} className="rounded-md p-2 mx-3 bg-neutral-200" key={item.label}>
        <li className="flex gap-2 ">
          {item.icon}
          <span>
            <b>{item.label}</b>
          </span>
        </li>
      </a>
    );
  }
  return (
    <a href={item.href} className="rounded-md p-2 mx-3 hover:bg-neutral-200" key={item.label}>
      <li className="flex gap-2 ">
        {item.icon}
        <span>{item.label}</span>
      </li>
    </a>
  );
};

NavItem.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
  }).isRequired,
  activeRef: PropTypes.string,
};

NavItem.defaultProps = {
  activeRef: null,
};

export default NavItem;
