import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

const Tabs = ({ tab, tabs, setTab, className }) => {
  return (
    <div className={`p-0 pt-2 flex justify-start bg-neutral-150 flex-wrap ${className}`}>
      {tabs.map((tabName) => (
        <button
          className={classNames({
            'bg-neutral-100 font-bold border-neutral-300 border border-b-0': tab === tabName,
            'border-neutral-300 border': tab !== tabName,
            'rounded-t-md ms-2 px-3 pb-0 pt-1': true,
          })}
          type="button"
          onClick={() => setTab(tabName)}
        >
          {tabName}
        </button>
      ))}
    </div>
  );
};

Tabs.propTypes = {
  tab: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  setTab: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Tabs.defaultProps = {
  className: '',
};

export default Tabs;
