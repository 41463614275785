import React from 'react';
import PropTypes from 'prop-types';

function isInternalURL(to) {
  try {
    const url = new URL(to, window.location.origin);
    return url.hostname === window.location.hostname;
  } catch {
    return false;
  }
}

const Link = ({ children, className, href, ...props }) => {
  if (isInternalURL(href)) {
    return (
      <a href={href} className={`font-semibold text-blue-600 hover:text-blue-800 ${className}`} {...props}>
        {children}
      </a>
    );
  }

  return (
    <a
      href={href}
      className={`font-semibold text-blue-600 hover:text-blue-800 ${className}`}
      {...props}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

Link.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  href: PropTypes.string,
};

Link.defaultProps = {
  className: '',
  href: '#',
};

export default Link;
