import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

const Message = ({ message }) => {
  return (
    <div
      key={message.message}
      className={classnames({
        'p-3 border rounded-lg': true,
        'alert-danger bg-red-100 border-red-600 border text-red-700': message.type === 'danger',
        'alert-success bg-green-100 border-green-600 text-green-700': message.type === 'success',
        'bg-yellow-100 border-yellow-600 text-yellow-700': message.type === 'warning',
      })}
    >
      {message.message}
    </div>
  );
};

Message.propTypes = {
  message: PropTypes.shape({
    message: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default Message;
