/* eslint-disable react/jsx-wrap-multilines */
import React, { useMemo, useState } from 'react';

import Label from 'components/base/Label';
import Button from 'components/base/Button';
import Input from 'components/base/Input';
import useLocalStorage from 'hooks/useLocalStorage';
import Message from './base/Message';
import Checkbox from './base/Checkbox';
import Link from './base/Link';

const emailPattern =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const RegistrationForm = () => {
  const [registerFirst, setRegisterFirst] = useLocalStorage('registerFirst', '');
  const [registerLast, setRegisterLast] = useLocalStorage('registerLast', '');
  const [registerEmail, setRegisterEmail] = useLocalStorage('registerEmail', '');
  const [registerPronouns, setRegisterPronouns] = useLocalStorage('registerPronouns', '');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [registerTerms, setRegisterTerms] = useState(false);
  const formRef = React.useRef(null);

  const [validationErrors, passwordInvalid, password2Invalid, registerEmailInvalid] = useMemo(() => {
    const errors = [];
    let pwInvalid = false;
    let pw2Invalid = false;
    let emailInvalid = false;

    if (password !== password2 && password.length > 0 && password2.length > 0) {
      errors.push('Passwords do not match');
      pw2Invalid = true;
    }

    if (password.length < 12 && password.length > 0) {
      errors.push('Password must be at least 12 characters');
      pwInvalid = true;
    }

    if (password.toLowerCase().includes(registerEmail.toLowerCase()) && registerEmail.length > 0) {
      errors.push('Password cannot contain email');
      pwInvalid = true;
    }

    if (!emailPattern.test(registerEmail) && registerEmail.length > 0) {
      errors.push('Invalid email');
      emailInvalid = true;
    }

    return [errors, pwInvalid, pw2Invalid, emailInvalid];
  }, [password, password2, registerEmail]);

  return (
    <form action="/user/register" method="post" className="flex flex-col gap-2" ref={formRef}>
      <p>Please use the same email associated with your purchase! We will use this email to authenticate your entry.</p>
      <p>
        By registering, you will receive a confirmation email from admin@hedron.network with a verification code.
        Verification is required to sign up for events. If you can't find the email, it may have been marked as spam. If
        you're still having trouble, please reach out to admin@hedron.network for assistance.
      </p>
      <Label>First Name</Label>
      <Input id="firstname" name="firstname" value={registerFirst} setValue={setRegisterFirst} />
      <Label>Last Name</Label>
      <Input id="lastname" name="lastname" value={registerLast} setValue={setRegisterLast} />
      <Label>Email</Label>
      <Input
        id="email"
        name="email"
        type="text"
        value={registerEmail}
        setValue={setRegisterEmail}
        invalid={registerEmailInvalid}
        valid={registerEmail.length > 0 && !registerEmailInvalid}
      />
      <Label>Pronouns (optional)</Label>
      <Input id="pronouns" name="pronouns" value={registerPronouns} setValue={setRegisterPronouns} />
      <Label>Password</Label>
      <Input
        id="password"
        type="password"
        name="password"
        value={password}
        setValue={setPassword}
        invalid={passwordInvalid}
        valid={password.length > 0 && !passwordInvalid}
      />
      <Label>Confirm Password</Label>
      <Input
        id="password2"
        type="password"
        name="password2"
        value={password2}
        setValue={setPassword2}
        invalid={password2Invalid}
        valid={password2.length > 0 && !password2Invalid}
      />
      <Checkbox
        id="acceptterms"
        name="acceptterms"
        label={
          <span>
            I accept the <Link href="/terms">Terms of Service</Link>
          </span>
        }
        setChecked={setRegisterTerms}
        checked={registerTerms}
        required
      />
      {validationErrors.length > 0 &&
        validationErrors.map((error) => <Message message={{ message: error, type: 'danger' }} />)}
      <Button
        outline
        color="primary"
        className="w-100 mt-3"
        disabled={validationErrors.length > 0}
        onClick={() => formRef.current.submit()}
      >
        Register
      </Button>
    </form>
  );
};

export default RegistrationForm;
