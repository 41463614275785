import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({ name, id, checked, setChecked, label, ...props }) => {
  return (
    <div className="flex items-center">
      <input
        className="form-checkbox"
        type="checkbox"
        name={name}
        id={id}
        checked={checked}
        onChange={(e) => setChecked(e.target.checked)}
        {...props}
      />
      <span className="ml-2 text-sm">{label}</span>
    </div>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
  label: PropTypes.string,
};

Checkbox.defaultProps = {
  checked: false,
  setChecked: () => {},
  label: '',
};

export default Checkbox;
