/* eslint-disable react/no-array-index-key */
import React from 'react';

import RenderToRoot from 'utils/RenderToRoot';
import useLocalStorage from 'hooks/useLocalStorage';

import Card from 'components/base/Card';
import Tabs from 'components/base/Tabs';
import CardBody from 'components/base/CardBody';
import Label from 'components/base/Label';
import Button from 'components/base/Button';
import Input from 'components/base/Input';
import Link from 'components/base/Link';
import RegistrationForm from 'components/RegistrationForm';

const LoginPage = () => {
  const [tab, setTab] = useLocalStorage('logintab', 'Login');
  const formRef = React.useRef(null);

  return (
    <Card className="mt-2">
      <Tabs tab={tab} setTab={setTab} tabs={['Login', 'Register']} className="rounded-t-md" />
      <CardBody>
        {tab === 'Login' && (
          <form action="/user/login" method="post" ref={formRef}>
            <Label>Email</Label>
            <Input id="email" name="email" type="text" />
            <Label>Password</Label>
            <Input id="password" type="password" name="password" />
            <p>
              <Link href="/user/forgot">Forgot Password?</Link>
            </p>
            <Button outline color="primary" className="mt-3" loading onClick={() => formRef.current.submit()}>
              Login
            </Button>
          </form>
        )}
        {tab === 'Register' && <RegistrationForm />}
      </CardBody>
    </Card>
  );
};

export default RenderToRoot(LoginPage);
