import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

const Input = ({ name, id, value, setValue, type, invalid, valid, ...props }) => {
  return (
    <input
      type={type}
      name={name}
      id={id}
      value={value}
      onChange={(event) => setValue(event.target.value)}
      className={classNames({
        'w-full border rounded-md p-1 focus:outline-0 focus:bg-blue-50 transition-all duration-200': true,
        'border-red-500 bg-red-50 focus:bg-red-100 input-invalid': invalid,
        'border-green-500 bg-green-50 focus:bg-green-100 input-valid': valid,
      })}
      {...props}
    />
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func,
  type: PropTypes.string.isRequired,
  invalid: PropTypes.bool,
  valid: PropTypes.bool,
};

Input.defaultProps = {
  setValue: () => {},
  invalid: false,
  valid: false,
};

export default Input;
