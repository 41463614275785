import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, Transition } from '@headlessui/react';

const Modal = ({ children, isOpen, setOpen, size }) => {
  return (
    <Transition.Root show={isOpen}>
      <Dialog as="div" className="relative z-30" onClose={() => setOpen(false)}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="flex min-h-full justify-center text-center items-start">
            <div className={`grow max-w-${size}`}>
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="max-h-screen p-4">
                  <Dialog.Panel className="relative transform overflow-hidden rounded-md bg-white text-left shadow-xl transition-all w-full flex flex-col max-h-modal">
                    {children}
                  </Dialog.Panel>
                </div>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
};

Modal.defaultProps = {
  size: 'md',
};

export default Modal;
