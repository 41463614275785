import React from 'react';
import PropTypes from 'prop-types';

import { XIcon } from '@primer/octicons-react';

const ModalHeader = ({ children, className, setOpen }) => {
  return (
    <div
      className={`bg-neutral-100 text-neutral-700 font-semibold text-xl p-2 border-b border-neutral-300 flex ${className}`}
    >
      {children}
      <button type="button" className="ml-auto" onClick={() => setOpen(false)}>
        <XIcon size={24} />
      </button>
    </div>
  );
};

ModalHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  setOpen: PropTypes.func.isRequired,
};

ModalHeader.defaultProps = {
  className: '',
};

export default ModalHeader;
