import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

const ModalBody = ({ children, className, fixed }) => {
  return (
    <div
      className={classNames({
        'p-3': true,
        'overflow-auto grow border-y': !fixed,
        [className]: className,
      })}
    >
      {children}
    </div>
  );
};

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  fixed: PropTypes.bool,
};

ModalBody.defaultProps = {
  className: '',
  fixed: false,
};

export default ModalBody;
