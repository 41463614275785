import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/base/Modal';
import ModalBody from 'components/base/ModalBody';
import Button from 'components/base/Button';
import ModalHeader from 'components/base/ModalHeader';

const ConfirmActionModal = ({ isOpen, setOpen, title, message, target, buttonText }) => {
  return (
    <Modal isOpen={isOpen} setOpen={setOpen} size="md">
      <ModalHeader setOpen={setOpen}>{title}</ModalHeader>
      <ModalBody className="flex flex-col gap-2">
        <p>{message}</p>
        <Button color="danger" href={target}>
          {buttonText}
        </Button>
      </ModalBody>
    </Modal>
  );
};

ConfirmActionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default ConfirmActionModal;
