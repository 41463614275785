/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
  CalendarIcon,
  ChevronLeftIcon,
  FileMediaIcon,
  MegaphoneIcon,
  PackageDependenciesIcon,
  PackageDependentsIcon,
  PackageIcon,
  PeopleIcon,
  PersonIcon,
  PinIcon,
  SignInIcon,
  SignOutIcon,
  StarIcon,
  ThreeBarsIcon,
  TrophyIcon,
  VersionsIcon,
} from '@primer/octicons-react';

import Link from 'components/base/Link';
import Message from 'components/base/Message';
import NavItem from 'components/base/NavItem';
import Copyright from 'components/Copyright';
import EnvContext from 'contexts/EnvContext';
import MessagesContext from 'contexts/MessagesContext';
import UserContext from 'contexts/UserContext';
import withModal from 'hooks/withModal';
import ConfirmActionModal from 'modals/ConfirmActionModal';

const ConfirmActionModalButton = withModal('a', ConfirmActionModal);

const loggedOffNavItems = [
  {
    label: 'Sign In',
    href: '/login/',
    icon: <SignInIcon className="w-6 h-6" />,
  },
];

const navItems = [
  {
    label: 'Your Events',
    href: '/user/events/',
    icon: <PinIcon className="w-6 h-6" />,
  },
  {
    label: 'Browse Events',
    href: '/events/',
    icon: <CalendarIcon className="w-6 h-6" />,
  },
  {
    label: 'Open Play',
    href: '/openplay/',
    icon: <PackageDependentsIcon className="w-6 h-6" />,
  },
  {
    label: 'Standings',
    href: '/standings/',
    icon: <TrophyIcon className="w-6 h-6" />,
  },
  {
    label: 'Cubes',
    href: '/cubes/',
    icon: <PackageIcon className="w-6 h-6" />,
  },
  {
    label: 'Your Account',
    href: '/user/account/',
    icon: <PersonIcon className="w-6 h-6" />,
  },
];

const adminNavItems = [
  {
    label: 'Manage Users',
    href: '/admin/users/',
    icon: <PeopleIcon className="w-6 h-6" />,
  },
  {
    label: 'Manage Cubes',
    href: '/admin/cubes/',
    icon: <PackageDependenciesIcon className="w-6 h-6" />,
  },
  {
    label: 'Cube Votes',
    href: '/admin/cubevotes/',
    icon: <StarIcon className="w-6 h-6" />,
  },
];

const judgeNavItems = [
  {
    label: 'Manage Events',
    href: '/judge/events/',
    icon: <VersionsIcon className="w-6 h-6" />,
  },
  {
    label: 'Manage Open Play',
    href: '/judge/openplay/',
    icon: <PackageDependentsIcon className="w-6 h-6" />,
  },
  {
    label: 'Judge Events',
    href: '/judge/judgeevents/',
    icon: <MegaphoneIcon className="w-6 h-6" />,
  },
  {
    label: 'Cube Photos',
    href: '/judge/cubephotos/',
    icon: <FileMediaIcon className="w-6 h-6" />,
  },
];

const MainLayout = ({ children, activeRef }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const user = React.useContext(UserContext);
  const messages = React.useContext(MessagesContext);
  const env = React.useContext(EnvContext);

  const Icon = collapsed ? ThreeBarsIcon : ChevronLeftIcon;

  return (
    <div className="bg-neutral-200">
      <div
        className={classNames({
          'md:grid min-h-screen': true,
          'md:grid-cols-sidebar': !collapsed,
          'md:grid-cols-sidebar-collapsed': collapsed,
          'transition-[grid-template-columns] duration-100 ease-in-out': true,
        })}
      >
        <div
          className={classNames({
            'z-20 bg-neutral-100 border-r border-neutral-400 min-h-screen': true,
            'hover:bg-neutral-200': collapsed,
            'hover:cursor-pointer': collapsed,
            'transition-all duration-300': true,
            'fixed md:translate-x-0': true,
            'w-[300px]': !collapsed,
            'w-16': collapsed,
            '-translate-x-full': !showSidebar,
          })}
          onClick={() => {
            if (collapsed) {
              setCollapsed(false);
            }
          }}
        >
          <div className="flex flex-col justify-between min-h-screen max-h-screen">
            <div
              className={classNames({
                'flex items-center': true,
                'p-4 justify-between': !collapsed,
                'py-4 justify-center': collapsed,
              })}
            >
              {!collapsed && (
                <div className="flex flex-row items-center gap-2">
                  <img src="/content/hedronlogo.png" alt="Hedro Network Logo" className="h-12 me-2" />
                  <span className="whitespace-nowrap font-bold text-lg">Hedron Network</span>
                </div>
              )}
              <button
                className="place-content-center hidden md:block"
                // 👇 set the collapsed state on click
                onClick={() => {
                  if (!collapsed) {
                    setCollapsed(true);
                  }
                }}
                type="button"
              >
                <Icon size={20} />
              </button>
            </div>
            <nav className="flex-grow overflow-y-auto">
              <ul className="my-2 flex flex-col gap-2 items-stretch">
                {!collapsed && (
                  <>
                    {(user ? navItems : loggedOffNavItems).map((item) => (
                      <NavItem item={item} activeRef={activeRef} />
                    ))}
                    {user && (
                      <ConfirmActionModalButton
                        className="rounded-md p-2 mx-3 hover:bg-neutral-200 hover:cursor-pointer"
                        modalProps={{
                          title: 'Sign Out',
                          message: 'Are you sure you want to sign out?',
                          target: '/user/logout/',
                          buttonText: 'Sign Out',
                        }}
                      >
                        <li className="flex gap-2 ">
                          <SignOutIcon className="w-6 h-6" />
                          <span>Sign Out</span>
                        </li>
                      </ConfirmActionModalButton>
                    )}
                    {user && user.roles.includes('Admin') && (
                      <>
                        <span className="text-neutral-500 text-sm font-bold uppercase px-3 my-2">Admin</span>
                        {adminNavItems.map((item) => (
                          <NavItem item={item} activeRef={activeRef} />
                        ))}
                      </>
                    )}
                    {user && (user.roles.includes('Judge') || user.roles.includes('Admin')) && (
                      <>
                        <span className="text-neutral-500 text-sm font-bold uppercase px-3 my-2">Judge</span>
                        {judgeNavItems.map((item) => (
                          <NavItem item={item} activeRef={activeRef} />
                        ))}
                      </>
                    )}
                  </>
                )}
              </ul>
            </nav>
          </div>
        </div>
        <div />
        <div className="flex flex-col items-left justify-center container mr-auto gap-2 min-h-screen">
          <nav
            className={classNames({
              'bg-white text-zinc-500': true, // colors
              'flex items-center': true, // layout
              'w-screen md:w-full sticky z-10 px-4 shadow-sm h-[73px] top-0 ': true, // positioning & styling
              // invisible on desktop
              'visible md:hidden': true,
            })}
          >
            <img src="/content/hedronlogo.png" alt="Hedron Logo" className="h-12 me-2" />
            <div className="font-bold text-lg">Hedron Network</div>
            <div className="flex-grow" />
            <button className="md:hidden" onClick={() => setShowSidebar((prev) => !prev)} type="button">
              <ThreeBarsIcon className="h-8 w-8" />
            </button>
          </nav>
          {user && !user.verified && (
            <div className="px-4 mt-4">
              <Message
                message={{
                  message: (
                    <>
                      Your account has not been verified. You will not be able to register for events until you verify
                      your email address. To verify your email address, click <Link href="/user/confirm/">here</Link>.
                    </>
                  ),
                  type: 'warning',
                }}
              />
            </div>
          )}
          <div className="px-4 mt-4">
            {messages.map((message) => (
              <Message message={message} />
            ))}
          </div>
          <div className="flex-grow px-4">{children}</div>
          <div className="flex flex-col gap-2 text-sm p-2">
            <div className="flex flex-row gap-2 items-center justify-center">
              <span className="font-bold">Version:</span>
              <span>{env.APPLICATION_VERSION}</span>
            </div>
            <div className="flex flex-row items-center justify-center">
              <Copyright />
            </div>
            <div className="flex flex-row gap-2 items-center justify-center">
              <Link href="/privacy/">Privacy</Link>
              <Link href="/terms/">Terms of Service</Link>
              <Link href="/cookies/">Cookies</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  activeRef: PropTypes.string,
};

MainLayout.defaultProps = {
  activeRef: null,
};

export default MainLayout;
